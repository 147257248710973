import React from 'react';
import AdSense from 'react-adsense';

const MyAdSense = () => (
    <AdSense.Google
        client='ca-pub-0629624412940420'
        slot='7725818649'
    />
);

export default MyAdSense;